<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st0" d="M108.3,101.1c-0.6-0.6-1.7-0.6-2.3,0l-6.2,6.2L41.4,49l4.3-4.3c1.4-1.4,1.3-3.8-0.4-5L18.9,19.8   c-1.3-0.9-3-0.8-4.1,0.3L1.7,33.2c-1.1,1.1-1.2,2.9-0.3,4.1l19.8,26.5c1.2,1.6,3.6,1.8,5,0.4l4.3-4.3L89,118.1l-6.2,6.2   c-0.6,0.6-0.6,1.7,0,2.3l55.6,55.6c6.8,6.8,17.9,7.6,25.1,1.3c7.9-7,8.2-19,0.9-26.4L108.3,101.1z"/>
      <path class="st0" d="M198.3,43.6c-0.5-2.3-3.4-3.2-5.1-1.5l-9.5,9.5c-6.4,6.4-16.8,7.1-23.6,1.2c-7.4-6.5-7.7-17.9-0.8-24.7   l9.9-9.9c1.7-1.7,0.9-4.6-1.5-5.1c-14.3-3.1-29.9,1.7-39.9,14.5c-9.2,11.7-10.8,27.5-5,40.7L95.8,95.3l4,4l2.2-2.2   c1.4-1.4,3.2-2.1,5.1-2.1c1.9,0,3.8,0.8,5.1,2.1l11.1,11.1l19.7-19.7c14.6,6.5,32.4,3.8,44.4-8.2C197.5,70.3,201,56.4,198.3,43.6z"/>
      <path class="st0" d="M76.6,125.5c0-1.9,0.8-3.8,2.1-5.1l2.2-2.2l-4-4l-48.4,48.4c-5.6,5.6-5.6,14.7,0,20.2c5.6,5.6,14.7,5.6,20.2,0   l41.1-41.1l-11.1-11.1C77.4,129.3,76.6,127.4,76.6,125.5z M39.3,180.9c-4.9,0-8.8-3.9-8.8-8.8c0-4.9,3.9-8.8,8.8-8.8   c4.9,0,8.8,3.9,8.8,8.8C48.1,176.9,44.1,180.9,39.3,180.9z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconTools',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

